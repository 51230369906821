<template>
  <a-modal v-model:visible="visible" :title="modalTitle" @ok="handleOk" :mask-closable="false">
    <div class="payperiod_wrapper h100 w100">
      <a-form-item label="客户名称"> <a-input style="width: 200px"></a-input></a-form-item>
      <a-form-item label="回款周期">
        <a-select :options="selectOptions" style="width: 200px; margin-right: 10px"> </a-select>
        <a-date-picker :show-today="false" dropdown-class-name="payperiod_calender_box"></a-date-picker>
      </a-form-item>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'PayPeriod',
  components: {},
  emits: ['afterSave'],
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
      selectOptions: [
        { label: '次月', value: '2thMonth' },
        { label: '次次月', value: '3thMonth' },
      ],
    };
  },
  computed: {
    modalTitle() {
      return `客户${this.rowData.customerOfCompanyName}回款周期`;
    },
  },
  methods: {
    handleOk() {
      this.visible = false;
      this.$emit('afterSave', { type: 'payPeriod' });
    },
  },
};
</script>

<style lang="less">
.payperiod_calender_box {
  .ant-calendar-header {
    display: none !important;
  }
}
.payperiod_wrapper {
  .ant-form-item {
    margin-bottom: 10px;
  }
}
</style>
