<template>
  <a-modal class="rebate_info_modal" v-model:visible="visible" :title="modalTitle" @ok="handleOk" :width="1000" :mask-closable="false">
    <div class="rebat_info_wrapper w100">
      <a-menu v-model:selectedKeys="mediaChannel" mode="vertical">
        <a-menu-item @click="mediaChannelChange(radio.prop)" v-for="radio in radioList" :key="radio.prop">{{ radio.label }} </a-menu-item>
      </a-menu>
      <div class="main_wrapper h100">
        <div class="customer_list w100" v-show="accountOwnerList?.length">
          <!-- <div> <a-checkbox v-model:checked="checkbox.checkAll" @change="onCheckAllChange"> 全选 </a-checkbox></div> -->
          <a-checkbox-group class="w100" v-model:value="checkbox.checkedList" @change="accountOwnerChange">
            <a-row>
              <a-col :span="12" v-for="item in accountOwnerList" :key="item">
                <a-checkbox :value="item">{{ item.invoiceName }} </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
        <div class="rebate_list w100">
          <!-- 代运营返点 -->
          <a-row>
            <a-col :span="24" class="rebate_title">
              <a-space> <span>代运营返点</span><PlusCircleOutlined @click="addItem(rebateInfo.one)" /> </a-space>
            </a-col>
            <a-col :span="24" v-for="(item, index) in rebateInfo.one" :key="index">
              <a-space>
                <a-range-picker
                  v-model:value="item.dateRange"
                  separator="至"
                  :mode="['month', 'month']"
                  format="YYYY-MM"
                  @panelChange="
                    val => {
                      handlePanelChange(val, item);
                    }
                  "
                />
                <a-input v-model:value="item.rebateRate" placeholder="返点值" type="number" oninput="if(value>100)value=value.slice(0,2);if(value<0)value=0"></a-input>
                <DeleteOutlined @click="deleteItem(rebateInfo.one, index, item)" />
              </a-space>
            </a-col>
          </a-row>
          <!-- 自运营返点 -->
          <a-row>
            <a-col :span="24" class="rebate_title">
              <a-space> <span>自运营返点</span><PlusCircleOutlined @click="addItem(rebateInfo.two)" /> </a-space>
            </a-col>
            <a-col :span="24" v-for="(item, index) in rebateInfo.two" :key="index">
              <a-space>
                <a-range-picker
                  v-model:value="item.dateRange"
                  separator="至"
                  :mode="['month', 'month']"
                  format="YYYY-MM"
                  @panelChange="
                    val => {
                      handlePanelChange(val, item);
                    }
                  "
                />
                <a-input v-model:value="item.rebateRate" placeholder="返点值" type="number" oninput="if(value>100)value=value.slice(0,2);if(value<0)value=0"></a-input>
                <DeleteOutlined @click="deleteItem(rebateInfo.two, index, item)" />
              </a-space>
            </a-col>
          </a-row>
          <!-- 服务费返点 -->
          <a-row>
            <a-col :span="24" class="rebate_title">
              <a-space> <span>服务费返点</span><PlusCircleOutlined @click="addItem(rebateInfo.three)" /> </a-space>
            </a-col>
            <a-col :span="24" v-for="(item, index) in rebateInfo.three" :key="index">
              <a-space>
                <a-range-picker
                  v-model:value="item.dateRange"
                  separator="至"
                  :mode="['month', 'month']"
                  format="YYYY-MM"
                  @panelChange="
                    val => {
                      handlePanelChange(val, item);
                    }
                  "
                />
                <a-input v-model:value="item.rebateRate" placeholder="返点值" type="number" oninput="if(value>100)value=value.slice(0,2);if(value<0)value=0"></a-input>
                <DeleteOutlined @click="deleteItem(rebateInfo.three, index, item)" />
              </a-space>
            </a-col>
          </a-row>
          <!-- 二代返点 -->
          <a-row>
            <a-col :span="24" class="rebate_title">
              <a-space> <span>二代返点</span><PlusCircleOutlined @click="addItem(rebateInfo.four, 'four')" /> </a-space>
            </a-col>
            <template v-for="(item, index) in rebateInfo.four" :key="index">
              <a-col :span="24">
                <a-space class="level2_head">
                  <a-select placeholder="请选择二代名称" v-model:value="item.rebateName">
                    <a-select-option v-for="agent in agentList" :key="agent" :value="agent.agentName">{{ agent.agentName }}</a-select-option>
                  </a-select>
                  <PlusCircleOutlined @click="addItem(item.children)" />
                  <DeleteOutlined @click="deleteItem(rebateInfo.four, index, item)" />
                </a-space>
              </a-col>
              <a-col :span="24" v-for="(item2, index2) in item.children" :key="index2">
                <a-space>
                  <a-range-picker
                    v-model:value="item2.dateRange"
                    :mode="['month', 'month']"
                    format="YYYY-MM"
                    @panelChange="
                      val => {
                        handlePanelChange(val, item2);
                      }
                    "
                  />
                  <a-input placeholder="返点值" v-model:value="item2.rebateRate" type="number" oninput="if(value>100)value=value.slice(0,2);if(value<0)value=0"></a-input>
                  <DeleteOutlined @click="deleteItem(item.children, index2, item2)" />
                </a-space>
              </a-col>
            </template>
          </a-row>
        </div>
      </div>
    </div>
    <template #footer>
      <a-row type="flex" justify="center">
        <a-button @click="visible = false">取消</a-button>
        <a-button @click="handleOk" type="primary" :loading="btnLoading">确定</a-button>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import customerRebate from '@/api/customer/rebate.js';
import { getAgentList } from '@/api/mediaAccount/mediaAccount.js';
import moment from 'moment';
import { Modal } from 'ant-design-vue';

export default {
  name: 'RebatInfo',
  components: { PlusCircleOutlined, DeleteOutlined },
  emits: ['afterSave'],
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const radioList = [
      { label: '腾讯广告', prop: 'GDT' },
      { label: '巨量引擎', prop: 'TT' },
      { label: '磁力引擎', prop: 'KS' },
    ];
    const newItem = {
      companyCode: 11,
      endTime: '',
      invoiceName: '',
      mediaChannel: '',
      rebateRate: '',
      startTime: '',
      sysCustomersId: 1,
      sysCustomersInvoiceId: 2,
      dateRange: [],
    };
    return {
      visible: false,
      btnLoading: false,
      newItem,
      radioList,
      mediaChannel: [],
      checkbox: {
        checkAll: false,
        checkedList: [],
      },
      accountOwnerList: [], //开户主体列表
      agentList: [],
      date: [],
      rebateInfo: {
        one: [{ rebateName: '代运营返点', ...newItem }],
        two: [{ rebateName: '自运营返点', ...newItem }],
        three: [{ rebateName: '服务费返点', ...newItem }],
        four: [{ rebateName: '二级代理', children: [{ ...newItem }] }],
      },
    };
  },
  watch: {
    async visible(val) {
      if (val) {
        this.mediaChannel = [this.rowData.channelType];
        await this.getAccountOwnerList();
        await this.getRebateList();
        await this.getAgentList();
      } else {
        this.rebateInfo = {
          one: [{ rebateName: '代运营返点', ...this.newItem }],
          two: [{ rebateName: '自运营返点', ...this.newItem }],
          three: [{ rebateName: '服务费返点', ...this.newItem }],
          four: [{ rebateName: '二级代理', children: [{ ...this.newItem }] }],
        };
        this.btnLoading = false;
      }
    },
  },
  async created() {},
  computed: {
    modalTitle() {
      return `${this.rowData.customerOfCompanyName}-返点`;
    },
  },
  methods: {
    // 保存
    async handleOk() {
      if (!this.checkbox.checkedList.length) {
        this.$message.info('请选择开户主体！');
        return;
      }
      this.btnLoading = true;
      let dataItem = {
        companyCode: 11,
        endTime: '2021-07',
        invoiceName: '开户主体1',
        mediaChannel: 'GDT',
        rebateName: '代运营返点',
        rebateRate: 1,
        startTime: '2021-06',
        sysCustomersId: 1,
        sysCustomersInvoiceId: 2,
      };
      dataItem.companyCode = dataItem.sysCustomersId = this.rowData.companyCode;
      const rebateList = [];
      Object.keys(this.rebateInfo).forEach(key => {
        let item = this.rebateInfo[key];
        if (key != 'four') {
          item.forEach(item2 => {
            let copy = { ...item2 };
            copy.rebateName = item[0].rebateName;
            copy.startTime = moment(copy.dateRange[0]).format('YYYY-MM-DD');
            copy.endTime = moment(copy.dateRange[1]).format('YYYY-MM-DD');
            rebateList.push(copy);
            delete copy.dateRange;
          });
        } else {
          item.forEach(item2 => {
            item2.children.forEach(item3 => {
              let copy = { ...item3 };
              copy.rebateName = item2.rebateName;
              copy.startTime = moment(copy.dateRange[0]).format('YYYY-MM-DD');
              copy.endTime = moment(copy.dateRange[1]).format('YYYY-MM-DD');
              rebateList.push(copy);
              delete copy.dateRange;
            });
          });
        }
      });
      const saveList = [];
      this.checkbox.checkedList.forEach(company => {
        rebateList.forEach(rebateItem => {
          let copy = { ...rebateItem };
          delete copy.deletable;
          copy.companyCode = this.rowData.companyCode;
          copy.invoiceName = company.invoiceName;
          copy.mediaChannel = this.mediaChannel[0];
          copy.sysCustomersId = company.companyCode;
          copy.sysCustomersInvoiceId = company.id;
          saveList.push(copy);
        });
      });
      const addData = [];
      const editData = [];
      saveList.forEach(item => {
        if (item.id && item.rebateRate) {
          editData.push(item);
        } else if (item.rebateRate) {
          addData.push(item);
        }
      });
      if (addData.length) {
        const res = await customerRebate.save(addData);
        if (res.code != 0) {
          this.$message.success('保存成失败');
          this.getRebateList();
        }
      }
      if (editData.length) {
        const res = await customerRebate.update(editData);
        if (res.code === 0) {
          this.$message.success('保存成功');
          this.visible = false;
        } else {
          this.$message.success('保存失败');
          this.getRebateList();
        }
      }
      this.visible = false;
    },
    // 添加
    addItem(item, type) {
      if (type === 'four') {
        item.push({ children: [], deletable: true });
      } else {
        item.push({ ...this.newItem, deletable: true });
      }
    },
    // 删除
    deleteItem(list, index, record) {
      if (list.length < 2) {
        return;
      }
      const _this = this;
      Modal.confirm({
        title: '删除',
        content: '该信息删除后将不予保留，请谨慎操作！',
        async onOk() {
          if (record.invoiceName) {
            const res = await customerRebate.delete([record.id]);
            if (res.code === 0) {
              _this.$message.info('已删除');
              list.splice(index, 1);
            }
            list.splice(index, 1);
          } else {
            list.splice(index, 1);
          }
        },
        onCancel() {},
      });
    },
    // 媒体渠道改变
    async mediaChannelChange(val) {
      this.mediaChannel = [val];
      const res = await this.getAccountOwnerList();
      if (res.code === 0) {
        await this.getRebateList();
      }
    },
    // 获取左侧开户主体列表
    async getAccountOwnerList() {
      const data = { ...this.rowData, mediaChannel: this.mediaChannel[0] };
      const res = await customerRebate.customerList(data);
      this.accountOwnerList = res?.list;
      return res;
    },
    // 获取返点数据
    async getRebateList() {
      const data = { ...this.rowData, mediaChannel: this.mediaChannel[0] };
      const res = await customerRebate.rebateList(data);
      if (!res.list) {
        return;
      }
      this.rebateList = res.list;
      const rebateData = res.list[0].info;
      this.rebateInfo.four = [];
      rebateData.forEach(rebateItem => {
        let copy = { ...rebateItem };
        copy.info = rebateItem.info.map(item => {
          item.dateRange = [moment(item.startTime), moment(item.endTime)];
          return item;
        });
        switch (rebateItem.name) {
          case '代运营返点':
            this.rebateInfo.one = copy.info;
            break;
          case '自运营返点':
            this.rebateInfo.two = copy.info;
            break;
          case '服务费返点':
            this.rebateInfo.three = copy.info;
            break;

          default:
            const item = {
              rebateName: copy.name,
              children: copy.info,
            };
            this.rebateInfo.four.push(item);
            break;
        }
      });

      if (this.rebateInfo.four.length === 0) {
        this.rebateInfo.four.push({ rebateName: '二级代理', children: [{ ...this.newItem }] });
      }

      // 设置选中的开户主体
      const nameList = res.list.map(item => item.name);
      [...new Set(nameList)].forEach(name => {
        this.accountOwnerList.find(company => {
          if (company.invoiceName === name) {
            this.checkbox.checkedList.push(company);
            return true;
          }
        });
      });
      if (this.accountOwnerList.length === this.checkbox.checkedList.length) {
        this.checkbox.checkAll = true;
      }
    },
    // 获取下拉框二级代理列表
    async getAgentList() {
      const res = await getAgentList();
      this.agentList = res.list;
      return res;
    },
    // 全选的change
    onCheckAllChange() {
      if (this.checkbox.checkAll) {
        this.checkbox.checkedList = this.accountOwnerList.map(item => item);
      } else {
        this.checkbox.checkedList = [];
      }
    },
    // 开户主体的 change
    accountOwnerChange() {
      const flag = this.checkbox.checkedList.length === this.accountOwnerList.length;
      this.checkbox.checkAll = flag;
    },
    // 月份选择框的change
    handlePanelChange(val, item) {
      item.dateRange = val;
    },
  },
};
</script>

<style scoped lang="less">
.rebat_info_wrapper {
  height: 500px;
  display: flex;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  overflow-x: auto;

  // 左侧媒体渠道列表
  .ant-menu {
    height: 100%;
    width: 160px;
    .ant-menu-item {
      height: 36px;
      font-size: 14px;
      margin: 0;
      &.ant-menu-item-selected {
        background: #197afb;
        color: white;
      }
    }
  }
  // 右侧内容
  .main_wrapper {
    box-sizing: border-box;
    flex-grow: 1;
    display: flex;
    width: calc(100% - 161px);
    flex-direction: column;
    min-width: 550px;

    // 开户主体
    .customer_list {
      background: #f5f7fa;
      padding: 12px 14px 0;
      :deep(.ant-checkbox-wrapper) {
        margin-bottom: 10px;
        color: #696969;
        &.ant-checkbox-wrapper-checked {
          color: #197afb;
        }
      }
    }
    // 返点信息列表
    .rebate_list {
      overflow: auto;
      padding: 12px 14px;
      flex-grow: 1;
      :deep(.ant-col-24) {
        margin-bottom: 15px;
        .ant-input {
          height: 32px;
        }
        .anticon-plus-circle svg {
          color: rgba(47, 112, 244, 1);
          font-size: 14px;
        }
        .anticon-delete {
          font-size: 14px;
          color: rgba(117, 117, 117, 1);
        }
      }
    }
  }
}
</style>
<style lang="less">
.rebate_info_modal {
  .ant-modal-title {
    font-size: 16px;
  }
}
</style>