import axios from '@/utils/axios';

// 客户返点相关操作
const apiUrl = {
  deleteRebate: '/customersRebate/delete', //删除客户返点
  getCustomerList: '/customersRebate/getCustomers', //根据渠道以及客户code获取所有开户主体
  getCustomersRebateList: '/customersRebate/getCustomersRebate', //根据渠道以及客户code获取绑定客户返点数据
  saveRebate: '/customersRebate/save', //保存客户返点
  updateRebate: '/customersRebate/update', //修改客户返点
};

const customerRebate = {
  delete: params => {
    return axios.post(apiUrl.deleteRebate, params);
  },
  customerList: params => {
    return axios.get(`${apiUrl.getCustomerList}/${params.companyCode}/${params.mediaChannel}`, params);
  },
  rebateList: params => {
    return axios.get(`${apiUrl.getCustomersRebateList}/${params.companyCode}/${params.mediaChannel}`, params);
  },
  save: params => {
    return axios.post(apiUrl.saveRebate, params);
  },
  update: params => {
    return axios.post(apiUrl.updateRebate, params);
  },
  rowData: {
    salerName: null,
    id: 31,
    companyCode: '2021010106',
    customerOfCompanyName: '几维鸟',
    mdaccountCount: 284,
    channelType: 'TT',
    channelNum: 3,
    invoiceCount: 10,
    cost: 5783230.871,
    balance: null,
    mdaccountIds: '1688937898034184,1688936806729735,1689944681046029,1693932940736525,1693932941209613,1694011918227463,1688752106620941,1688833832879175,1688859001912333,1688917340101645,1689190658710541,1689190659712007,1689190660213774,1689190660773896,1689305834284046,1689305833333767,1689305836259400,1689305835206670,1689491880948750,1690003799631885,1690003800228871,1690123910756366,1690823754385422,1690823753904136,1691134918853639,1692120250549320,1692120251170829,1692465268044871,1692741324623880,1692741325229064,1693897914641416,1693897916303374,1693897917572110,1693897915694094,1693897916901383,1694292255532109,1694623697158216,1694623697758221,1694642862873677,1694898570724365,1694899180968967,1696462718210055,1696527160841223,1698175355888712,1698177635803150,1685624636307527,1685624317897736,1685686270546957,1686833436940295,1686833436534797,1686833437397000,1686833438319687,1686833438869517,1686833439290376,1686833440050183,1686833439677512,1686833437820935,1686833462299655,1686833440477256,1686833462684680,1686',
  },
};
export default customerRebate;
// export function deleteRebate(params) {
//   return axios.post(apiUrl.deleteRebate, params);
// }
// export function getCustomerList(params) {
//   return axios.get(`${apiUrl.getCustomerList}/${params.companyCode}/${params.mediaChannel}`, params);
// }
// export function getCustomersRebateList(params) {
//   return axios.get(`${apiUrl.getCustomersRebateList}/${params.companyCode}/${params.mediaChannel}`, params);
// }
// export function saveRebate(params) {
//   return axios.post(apiUrl.saveRebate, params);
// }
// export function updateRebate(params) {
//   return axios.post(apiUrl.updateRebate, params);
// }
