/*
 * @Author: zhangruifeng
 * @Date: 2021-05-24 12:58:07
 * @LastEditors: zhangruifeng
 * @LastEditTime: 2021-05-27 14:20:11
 */
import axios from '@/utils/axios';
const apiUrl = {
    //主页的接口
    getCustomerAllList: 'sys/customers/getList',
    getCustomerPage: 'sys/customers/getCustomerPage', //获取客户分页信息
    getCustomersInvoiceList: 'sys/customersInvoice/getCustomersInvoiceList', //查询开户主题页面

    //新增界面的接口(增)
    saveCustomer: 'sys/customers/saveCustomer', //保存客户
    saveInvoiceNameList: 'sys/customers/getInvoiceNameListByChannel',
    saveUserByJob: 'sys/user/getUserByJob',

    //编辑界面的接口(改)
    updateCustomer: 'sys/customers/updateCustomer',
    updateCustomerByCompanyCode: 'sys/customersBasicInfo/getByCompanyCode',

    //查询页面的接口(查)
    viewCustomerMessage: 'sys/customersBasicInfo/getBasicByCompanyCode', //根据公司Code获取客户基础信息
    viewCustomerInvoiceInfo: 'sys/customers/getCustomerInvoiceInfo', //查询界面-获取客户开户主体信息
    viewCustomerMediaInfo: 'sys/customers/getCustomerMediaInfo', //查看界面-获取客户媒体信息
    viewCustomerProjectInfo: 'sys/customers/getCustomerProjectInfo', //获取客户产品信息
    viewCustomerMdAccountInfo: 'sys/customers/getCustomerMdAccountInfo', //查看界面-获取客户媒体账号信息
    viewCustomerInvoiceBasicInfo: 'sys/customersInvoiceBasicInfo/getCustomerInvoiceBasicInfo', //查看界面-获取客户的开票信息
};

//查询接口
export function getCustomerAllListData() {
    return axios.get(apiUrl.getCustomerAllList);
}

export function getCustomerPageData(params) {
    return axios.post(apiUrl.getCustomerPage, params);
}

export function getCustomersInvoiceListData(params) {
    return axios.post(apiUrl.getCustomersInvoiceList, params);
}

// 新增界面(增)

export function saveCustomerData(params) {
    return axios.post(apiUrl.saveCustomer, params);
}

export function saveInvoiceNameListData(params) {
    return axios.post(apiUrl.saveInvoiceNameList, params);
}

export function saveUserByJobData(job) {
    return axios.get(apiUrl.saveUserByJob + `/${job}`);
}

//编辑界面(改)
export function updateCustomerData(params) {
    return axios.post(apiUrl.updateCustomer, params);
}

export function updateCustomerByCompanyCodeData(companyCode) {
    return axios.get(apiUrl.updateCustomerByCompanyCode + `/${companyCode}`);
}

//查看页面(查)
export function viewCustomerMessageData(companyCode) {
    return axios.get(apiUrl.viewCustomerMessage + `/${companyCode}`);
}

export function viewCustomerInvoiceInfoData(params) {
    return axios.post(apiUrl.viewCustomerInvoiceInfo, params);
}

export function viewCustomerMediaInfoData(params) {
    return axios.post(apiUrl.viewCustomerMediaInfo, params);
}

export function viewCustomerProjectInfoData(params) {
    return axios.post(apiUrl.viewCustomerProjectInfo, params);
}

export function viewCustomerMdAccountInfoData(params) {
    return axios.post(apiUrl.viewCustomerMdAccountInfo, params);
}

export function viewCustomerInvoiceBasicInfoData(params) {
    return axios.post(apiUrl.viewCustomerInvoiceBasicInfo, params);
}