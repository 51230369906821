<!--
 * @Author: zhangruifeng
 * @Date: 2021-05-17 19:12:44
 * @LastEditors: LiuXin
 * @LastEditTime: 2022-01-06 19:04:05
-->
<template>
  <div class="customer_manage w100 h100">
    <a-row class="filter_row w100">
      <a-col :span="1" style="line-height: 40px">筛选：</a-col>
      <a-col :span="4">
        <a-form-item :wrapper-col="{ span: 24 }">
          <a-input v-model:value="customerFilter.customerOfCompanyName" placeholder="请输入客户名称" style="width: 100%" @press-enter="enterCustomerSearch" />
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item :wrapper-col="{ span: 24 }">
          <a-select :max-tag-count="2" mode="multiple" v-model:value="customerFilter.channelTypeList" placeholder="请选择媒体" style="width: 100%" allow-clear>
            <a-select-option value="TT">巨量引擎</a-select-option>
            <a-select-option value="GDT">腾讯广告</a-select-option>
            <a-select-option value="KS">磁力引擎</a-select-option>
            <a-select-option value="QC">巨量千川</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item :wrapper-col="{ span: 24 }">
          <a-select :max-tag-count="2" mode="multiple" v-model:value="customerFilter.invoiceIdList" placeholder="请选择开户主体" style="width: 100%" allow-clear :show-search="true" option-filter-prop="label" :filter-option="true">
            <a-select-option v-for="item in customersInvoiceList" :label="item.invoiceName" :key="item.id" :value="item.invoiceName">{{ item.invoiceName }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="11">
        <a-form-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
          <a-space class="float-r">
            <a-button type="primary" @click="onFilterCustomerBtnClick" :disabled="homeTableLoading">筛选</a-button>
            <a-button @click="onResetCustomerBtnClick" :disabled="homeTableLoading">重置</a-button>
          </a-space>
        </a-form-item>
      </a-col>
    </a-row>
    <div class="table_wrapper w100">
      <a-row v-hasPermission="'CUSTOMER_ADD'" style="margin-bottom: 12px">
        <a-button type="primary" @click="onAddCustomerBtnClick" :disabled="homeTableLoading"> <PlusCircleOutlined style="font-size: 14px" /> 新建客户</a-button>
      </a-row>
      <!-- 客户管理表格 -->
      <a-table size="small" class="customer_manage_table" :row-key="record => record.companyCode" :data-source="customerDataSource" :columns="customerColumns" style="width: 100%" bordered :pagination="homeTablePagination" @change="homeTableChange" :loading="homeTableLoading" :scroll="{ y: 300 }">
        <template #channelType="{ record }">
          {{ mapColumn(record.channelType) }}
        </template>
        <template #cost="{ record }">
          {{ record.cost > 0 ? (record.cost / 10000).toFixed(2) + ' 万元' : 0 }}
        </template>
        <template #channelNum="{ record }">
          {{ record.channelNum ? record.channelNum : '0' }}
        </template>

        <template #operation="{ record }">
          <a-space>
            <a v-hasPermission="'CUSTOMER_EDIT'" @click="onEditBtnClick(record)">编辑</a>
            <a v-hasPermission="'CUSTOMER_VIEW'" @click="onViewBtnClick(record)">查看</a>
            <a v-hasPermission="'CUSTOMER_TRBATE'" @click="operationClick(record, 'rebate')">返点</a>
            <!-- <a @click="operationClick(record, 'payPeriod')">回款周期</a> -->
          </a-space>
        </template>
      </a-table>
    </div>

    <!--新增客户会话框  -->
    <a-modal :mask-closable="false" v-model:visible="addCustomer.addModalVisible" title="新建客户信息" @ok="confirmAddCustomer" @cancel="cancelAddCustomer" class="add-modal">
      <a-form style="min-width: 550px" ref="addModalForm" :model="addCustomer.addCustomerData" :rules="addCustomer.rules">
        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" label="客户名称" name="customerOfCompanyName">
          <a-select v-model:value="addCustomer.addCustomerData.customerOfCompanyName" option-filter-prop="label" placeholder="请选择客户名称" style="width: 100%" :max-tag-count="2" allow-clear show-search @search="onCreateCusSelectSearch" @select="onCreateCusSelect">
            <template #notFoundContent>
              <a-empty :image="simpleImage">
                <template #description>
                  <span>暂无数据</span>
                </template>
              </a-empty>
            </template>
            <a-select-option :value="item.customerOfCompanyName" :label="item.customerOfCompanyName" v-for="(item, index) in addCustomer.allCustomerList" :key="index">{{ item.customerOfCompanyName }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" label="开户主体">
          <a-select show-arrow allow-clear mode="multiple" v-model:value="addCustomer.addCustomerData.invoiceNameList" placeholder="请选择开户主体">
            <a-select-option v-for="(item, index) in addCustomer.invoiceNameList" :value="item" :key="index">{{ item }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" label="新建产品">
          <a-switch v-model:checked="formState.delivery" />
        </a-form-item>
        <a-form-item v-show='formState.delivery == true' :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" label="产品类型">
          <a-radio-group v-model:value="addCustomer.projectNameType" button-style="solid">
            <a-radio :value="0"> 同客户名称 </a-radio>
            <a-radio :value="1"> 自定义 </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-show='formState.delivery == true' :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" label="产品名称">
          <a-input readOnly v-if='formState.delivery == true && addCustomer.projectNameType==0' v-model:value.trim="addCustomer.addCustomerData.customerOfCompanyName"  />
          <a-input v-if='formState.delivery == true && addCustomer.projectNameType==1' v-model:value.trim="addCustomer.projectName" placeholder="请输入产品名称" />
        </a-form-item>
        <a-divider orientation="left">媒体与销售</a-divider>

        <!-- (新增)媒体与销售(媒体和销售对应关系) -->
        <!-- 媒体 -->
        <a-row class="addModalInput" v-for="(item, index) in arrList" :key="index">
          <a-row class="addModalInput-content">
            <a-col :span="24">
              <a-col :span="3" v-if="index == 0">
                <div @click="addChannel(index)">+</div>
              </a-col>
              <a-col :span="3" v-else> </a-col>
              <a-col :span="16">
                <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :label="`媒体${index + 1}`">
                  <a-select allow-clear v-model:value="item.channelType" style="width: 100%" @change="deselectAddChannel" placeholder="请选择媒体">
                    <a-select-option v-for="every in addCustomer.channelList" :disabled="filterAddExist(every.value)" :value="every.value" :key="every.value">{{ every.text }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="5" v-if="index !== 0">
                <a-popconfirm title="删除后信息不允保留,请谨慎选择?" ok-text="确定" cancel-text="取消" @confirm="deleteChannel(arrList, index)">
                  <a style="margin-left: 18px">删除</a>
                </a-popconfirm>
              </a-col>
              <a-col :span="4" v-else> </a-col>
            </a-col>
          </a-row>

          <!-- 销售 -->
          <a-row class="addModalInput-content">
            <a-col :span="24" v-for="(option, key) in item.sale" :key="key">
              <a-col :span="3" v-if="key == 0">
                <div @click="addSaler(item.sale)">+</div>
              </a-col>
              <a-col :span="3" v-else> </a-col>
              <a-col :span="16">
                <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :label="`销售${key + 1}`">
                  <a-select allow-clear v-model:value="option.salerId" placeholder="销售人员">
                    <a-select-option v-for="one in addCustomer.saleList" :key="one.userId" :value="one.userId">{{ one.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="5" v-if="key !== 0">
                <a-popconfirm title="删除后信息不允保留,请谨慎选择?" ok-text="确定" cancel-text="取消" @confirm="deleteSaler(item.sale, key)">
                  <a style="margin-left: 18px">删除</a>
                </a-popconfirm>
              </a-col>
              <a-col :span="4" v-else> </a-col>
            </a-col>
          </a-row>
        </a-row>
      </a-form>
    </a-modal>

    <a-modal v-model:visible="addCustomer.selectExiVisible" title="是否进入编辑" @ok="confirmEnterEdit" @cancel="cancelEnterEdit">
      <p>您选择的客户已经存在！点击确认进行客户编辑.</p>
    </a-modal>

    <!--改  编辑客户会话框 -->
    <a-modal v-model:visible="editCustomer.modalVisible" title="编辑客户信息" @ok="confirmEditCustomer" @cancel="cancelEditCustomer" class="add-modal editMdal">
      <div class="w100 h100" style="min-width: 600px">
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }" :model="editCustomer.editCustomerData" ref="editModal" :rules="editCustomer.editRules" class="editMdal-form">
          <a-row>
            <a-col :span="12">
              <a-form-item label="客户名称" name="customerOfCompanyName">
                <a-input v-model:value="editCustomer.editCustomerData.customerOfCompanyName" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="邮箱地址" name="email">
                <a-input v-model:value="editCustomer.editCustomerData.email" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="生日">
                <a-input v-model:value="editCustomer.editCustomerData.birthDate" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="商务联系人"> <a-input v-model:value="editCustomer.editCustomerData.name" /> </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="喜好">
                <a-input v-model:value="editCustomer.editCustomerData.hobby" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="联系方式" name="phone">
                <a-input v-model:value="editCustomer.editCustomerData.phone" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="联系地址">
                <a-input v-model:value="editCustomer.editCustomerData.address" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="开户主体">
                <a-select @deselect="customerDeselect" :max-tag-count="3" mode="multiple" v-model:value="editCustomer.invoiceNameList">
                  <a-select-option v-for="(item, index) in addCustomer.invoiceNameList" :value="item" :key="index">{{ item }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <!-- 发票信息 -->
        <a-collapse v-model:activeKey="editCustomer.activeKey" style="margin-top: 4rem" class="collapse-panel">
          <a-collapse-panel key="1" header="开票联系人">
            <div style="float: right; margin-bottom: 0.5rem">
              <a-button type="primary" @click="addTicket">新增发票</a-button>
            </div>
            <a-row style="clear: both; margin-top: 1.8rem" v-for="(item, index) in editCustomer.customersInvoiceBasicInfoEntityList" :key="index">
              <a-col :span="24" style="height: 30px; line-height: 30px">
                <a-row>
                  <a-col :span="12">{{ `发票信息${index + 1}` }}</a-col>
                  <a-col :span="12">
                    <a-popconfirm title="删除后信息不予保留，请谨慎选择!" ok-text="删除" cancel-text="取消" @confirm="deleteTicketMessage(index)">
                      <a style="float: right; margin-right: 1rem">删除</a>
                    </a-popconfirm>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="甲方名称">
                  <a-input v-model:value="item.jiaName" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="公司全称">
                  <a-input v-model:value="item.companyName" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="公司地址">
                  <a-input v-model:value="item.companyAddress" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="纳税人识别号">
                  <a-input v-model:value="item.taxpayerNumber" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="开票联系人">
                  <a-input v-model:value="item.invoiceContact" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="开票地址">
                  <a-input v-model:value="item.invoiceAddress" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="开票联系人电话">
                  <a-input v-model:value="item.invoiceContactPhone" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="开票电话">
                  <a-input v-model:value="item.invoicePhone" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="微信号">
                  <a-input v-model:value="item.weChat" />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="开户银行">
                  <a-input v-model:value="item.accountsBank" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="开户账号">
                  <a-input v-model:value="item.accountsNumber" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="邮箱地址">
                  <a-input v-model:value="item.email" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-collapse-panel>
          <a-collapse-panel key="2" header="合同收件人">
            <!-- 公司 合同收件人信息集合 -->
            <div style="float: right; margin-bottom: 0.5rem">
              <a-button type="primary" @click="addReceive">新增收件</a-button>
            </div>
            <a-row style="clear: both" v-for="(item, index) in editCustomer.customersContractAddresseeEntities" :key="index">
              <a-col :span="24" style="height: 30px; line-height: 30px">
                <a-row>
                  <a-col :span="12">{{ `收件信息${index + 1}` }}</a-col>
                  <a-col :span="12">
                    <a-popconfirm title="删除后信息不予保留，请谨慎选择!" ok-text="删除" cancel-text="取消" @confirm="deleteReceiveMessage(index)">
                      <a style="float: right; margin-right: 1rem">删除</a>
                    </a-popconfirm>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="合同收件人名称">
                  <a-input v-model:value="item.contractAddressee" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="邮寄地址">
                  <a-input v-model:value="item.mailAddress" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="合同收件人电话">
                  <a-input v-model:value="item.contractAddresseePhone" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="邮箱地址">
                  <a-input v-model:value="item.email" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="公司地址">
                  <a-input v-model:value="item.companyAddress" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" label="微信号">
                  <a-input v-model:value="item.weChat" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-collapse-panel>
          <a-collapse-panel key="3" header="媒体与销售">
            <a-row class="information">
              <h3>媒体与销售</h3>
            </a-row>
            <!-- (编辑)媒体与销售 -->
            <!-- 媒体 -->
            <a-row class="addModalInput" v-for="(item, index) in editCustomer.editMediaSale" :key="index">
              <a-row class="addModalInput-content">
                <a-col :span="24">
                  <a-col :span="3" v-if="index == 0" style="padding-left: 17px">
                    <div @click="addEditChannel()" style="line-height: 0.3rem">+</div>
                  </a-col>
                  <a-col :span="3" v-else style="padding-left: 17px"> </a-col>
                  <a-col :span="17">
                    <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :label="`媒体${index + 1}`">
                      <a-select v-model:value="item.channelType" style="width: 100%" @change="selectEditChannel" placeholder="请选择媒体">
                        <a-select-option v-for="every in addCustomer.channelList" :disabled="filterUpdateExist(every.value)" :value="every.value" :key="every.value">{{ every.text }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="4" v-if="index !== 0">
                    <a-popconfirm title="删除后信息不允保留,请谨慎选择?" ok-text="确定" cancel-text="取消" @confirm="deleteEditChannel(editCustomer.editMediaSale, index)">
                      <a style="margin-left: 4px">删除</a>
                    </a-popconfirm>
                  </a-col>
                  <a-col :span="4" v-else> </a-col>
                </a-col>
              </a-row>

              <!-- 销售 -->
              <a-row class="addModalInput-content">
                <a-col :span="24" v-for="(option, key) in item.sale" :key="key">
                  <a-col :span="3" v-if="key == 0" style="padding-left: 17px">
                    <div @click="addSaler(item.sale)" style="line-height: 0.3rem">+</div>
                  </a-col>
                  <a-col :span="3" v-else style="padding-left: 17px"> </a-col>
                  <a-col :span="17">
                    <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :label="`销售${key + 1}`">
                      <a-select v-model:value="option.salerId" placeholder="销售人员" style="width: 100%">
                        <a-select-option v-for="one in addCustomer.saleList" :key="one.userId" :value="one.userId">{{ one.name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="4" v-if="key !== 0">
                    <a-popconfirm title="删除后信息不允保留,请谨慎选择?" ok-text="确定" cancel-text="取消" @confirm="deleteSaler(item.sale, key)">
                      <a style="margin-left: 4px">删除</a>
                    </a-popconfirm>
                  </a-col>
                  <a-col :span="4" v-else> </a-col>
                </a-col>
              </a-row>
            </a-row>
          </a-collapse-panel>
        </a-collapse>
      </div>

      <!-- *********************************** -->
    </a-modal>

    <!--查 客户的详情抽屉页面 -->
    <a-drawer width="50%" :title="`${customerDetail.customerName}客户信息`" placement="right" :closable="false" v-model:visible="customerDetail.customerDetailsVisible" class="cusotmer-drawer">
      <a-tabs v-model:activeKey="customerDetail.activeKey">
        <a-tab-pane key="1" tab="基本信息">
          <a-form :rules="customerDetail.rules">
            <a-form-item label="客户名称" :label-col="labelCol" :wrapper-col="wrapperCol" required>
              <a-input :disabled="true" v-model:value="customerDetail.customerDetailsMessage.customerOfCompanyName" />
            </a-form-item>
            <a-form-item label="联系人" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input :disabled="true" v-model:value="customerDetail.customerDetailsMessage.name" />
            </a-form-item>
            <a-form-item label="联系方式" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input :disabled="true" v-model:value="customerDetail.customerDetailsMessage.phone" />
            </a-form-item>
            <a-form-item label="邮箱地址" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input :disabled="true" v-model:value="customerDetail.customerDetailsMessage.email" />
            </a-form-item>
            <a-form-item label="生日" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input :disabled="true" v-model:value="customerDetail.customerDetailsMessage.birthDate" />
            </a-form-item>
            <a-form-item label="喜好" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input :disabled="true" v-model:value="customerDetail.customerDetailsMessage.hobby" />
            </a-form-item>
            <a-form-item label="联系地址" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input :disabled="true" v-model:value="customerDetail.customerDetailsMessage.address" />
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="`开户主体(${customerDetail.mdaccountTotal})`" force-render>
          <a-table table-layout="auto" row-key="invoiceName" bordered size="small" :data-source="customerDetail.mdaccountList" :columns="customerDetail.subjectColumns" @change="viewSubjectTable" :pagination="customerDetail.mdaccountPagination">
            <template #invoiceName="{ record }">
              {{ record.invoiceName || record.invoiceName == 0 ? record.invoiceName : '— —' }}
            </template>
            <template #mdaccountCount="{ record }">
              {{ record.mdaccountCount || record.mdaccountCount == 0 ? record.mdaccountCount : '— —' }}
            </template>
            <template #balance="{ record }">
              {{ record.balance || record.balance == 0 ? record.balance : '— —' }}
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="3" :tab="`媒体(${customerDetail.customerMediaTotal})`">
          <a-table row-key="id" table-layout="auto" size="small" style="width: 100%" bordered :data-source="customerDetail.customerMediaMessage" :columns="customerDetail.mediaColumns" :pagination="customerDetail.mediaPagination" @change="viewMediaTable">
            <template #channelType="{ record }">
              {{ record.channelType === null || record.channelType === '' ? '— —' : mapColumn(record.channelType) }}
            </template>
            <template #salerName="{ record }">
              {{ record.salerName === null || record.salerName === '' ? '— —' : record.salerName }}
            </template>
            <template #mdaccountCount="{ record }">
              {{ record.mdaccountCount === null || record.mdaccountCount === '' ? '— —' : record.mdaccountCount }}
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="4" :tab="`产品(${customerDetail.customerProductTotal})`">
          <a-table row-key="projectCode" table-layout="auto" size="small" bordered :data-source="customerDetail.customerProjectMessage" :columns="customerDetail.productColumns" :pagination="customerDetail.productPagination" @change="viewProductTable">
            <template #projectCode="{ record }">
              {{ record.projectCode === null || record.projectCode === '' ? '— —' : record.projectCode }}
            </template>
            <template #projectName="{ record }">
              {{ record.projectName === null || record.projectName === '' ? '— —' : record.projectName }}
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="5" :tab="`媒体账号(${customerDetail.customerMediaAccountTotal})`">
          <a-table :scroll="{ x: 1250 }" row-key="mdaccountId" table-layout="auto" size="small" bordered :data-source="customerDetail.costomerMediaAccount" :columns="customerDetail.accountColumns" @change="viewAccountTable" :pagination="customerDetail.mediaAccountPagination" :loading="customerDetail.showMediaAccountLoading">
            <template #mdaccountId="{ record }">
              {{ record.mdaccountId === null || record.mdaccountId === '' ? '— —' : record.mdaccountId }}
            </template>
            <template #projectCode="{ record }">
              {{ record.projectCode === null || record.projectCode === '' ? '— —' : record.projectCode }}
            </template>
            <template #projectName="{ record }">
              {{ record.projectName === null || record.projectName === '' ? '— —' : record.projectName }}
            </template>
            <template #channelType="{ record }">
              {{ record.channelType === null || record.channelType === '' ? '— —' : mapColumn(record.channelType) }}
            </template>
            <template #invoiceName="{ record }">
              {{ record.invoiceName === null || record.invoiceName === '' ? '— —' : record.invoiceName }}
            </template>
            <template #name>
              {{ '— —' }}
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="6" :tab="`财务信息(${customerDetail.financeMessageTotal})`">
          <a-collapse v-model:activeKey="customerDetail.collapseActiveKey">
            <a-row class="onExistData" v-if="customerDetail.financeMessage.length == 0">
              <div class="onExistData-box">
                <a-empty :image="simpleImage">
                  <template #description>
                    <span>暂无数据</span>
                  </template>
                </a-empty>
              </div>
            </a-row>
            <a-collapse-panel v-else :key="`${index + 1}`" :header="`发票信息${index + 1}`" v-for="(item, index) in customerDetail.financeMessage">
              <a-form>
                <a-row>
                  <a-col :span="12">
                    <a-form-item :label-col="{ span: 9 }" :wrapper-col="{ span: 14 }" label="甲方名称"> <a-input v-model:value="item.jiaName" /> </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item :label-col="{ span: 9 }" :wrapper-col="{ span: 14 }" label="公司全称"> <a-input v-model:value="item.companyName" /> </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item :label-col="{ span: 9 }" :wrapper-col="{ span: 14 }" label="公司地址"> <a-input v-model:value="item.companyAddress" /> </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item :label-col="{ span: 9 }" :wrapper-col="{ span: 14 }" label="纳税人识别号"> <a-input v-model:value="item.taxpayerNumber" /> </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item :label-col="{ span: 9 }" :wrapper-col="{ span: 14 }" label="合同收件人"> <a-input v-model:value="item.contractAddressee" /> </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item :label-col="{ span: 9 }" :wrapper-col="{ span: 14 }" label="合同人电话"> <a-input v-model:value="item.contractAddresseePhone" /> </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item :label-col="{ span: 9 }" :wrapper-col="{ span: 14 }" label="开票电话"> <a-input v-model:value="item.invoicePhone" /> </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item :label-col="{ span: 9 }" :wrapper-col="{ span: 14 }" label="开票地址"> <a-input v-model:value="item.invoiceAddress" /> </a-form-item>
                  </a-col>

                  <a-col :span="12">
                    <a-form-item :label-col="{ span: 9 }" :wrapper-col="{ span: 14 }" label="开户银行"> <a-input v-model:value="item.accountsBank" /> </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item :label-col="{ span: 9 }" :wrapper-col="{ span: 14 }" label="微信号"> <a-input v-model:value="item.weChat" /> </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item :label-col="{ span: 9 }" :wrapper-col="{ span: 14 }" label="邮箱地址"> <a-input v-model:value="item.mailAddress" /> </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </a-collapse-panel>
          </a-collapse>
        </a-tab-pane>
      </a-tabs>
    </a-drawer>

    <!-- 返点信息 -->
    <rebate-info ref="rebateInfo" :row-data="rowData" @after-save="afterSave"></rebate-info>

    <!-- 回款周期 -->
    <pay-period ref="payPeriod" :row-data="rowData" @after-save="afterSave"></pay-period>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { Empty } from 'ant-design-vue';
import { getCustomerAllListData, getCustomerPageData, getCustomersInvoiceListData, saveCustomerData, saveInvoiceNameListData, saveUserByJobData, updateCustomerData, updateCustomerByCompanyCodeData, viewCustomerMessageData, viewCustomerInvoiceInfoData, viewCustomerMediaInfoData, viewCustomerProjectInfoData, viewCustomerMdAccountInfoData, viewCustomerInvoiceBasicInfoData } from '@/api/customer/customerManage.js';
import RebateInfo from './customerManage/RebateInfo.vue';
import PayPeriod from './customerManage/PayPeriod.vue';
import { PlusCircleOutlined } from '@ant-design/icons-vue';
export default {
  components: { RebateInfo, PayPeriod, PlusCircleOutlined },
  data() {
    return {
      formState : {
        delivery: false,
      },
      rowData: {},
      homeTableLoading: false, //主页表格的加载显示
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      locale: zhCN, //中文
      customerFilter: {}, //筛选的字段
      homeTablePagination: {
        //主页表单的分页器
        limit: '30',
        page: '1',
        pageSize: 30,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
      },
      customerDataSource: [], //主页表格的数据
      customerColumns: [
        {
          title: '客户名称',
          dataIndex: 'customerOfCompanyName',
          key: 'customerOfCompanyName',
        },
        {
          title: '媒体数量',
          dataIndex: 'channelNum',
          key: 'channelNum',
          slots: {
            customRender: 'channelNum',
          },
        },
        {
          title: '开户主体数量',
          dataIndex: 'invoiceCount',
          key: 'invoiceCount',
        },
        {
          title: '媒体账号数量',
          dataIndex: 'mdaccountCount',
          key: 'mdaccountCount',
        },
        {
          title: '累计消耗',
          dataIndex: 'cost',
          key: 'cost',
          slots: {
            customRender: 'cost',
          },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          width: 200,
          slots: {
            customRender: 'operation',
          },
        },
      ],
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },

      arrList: [{ channelType: undefined, sale: [{ salerId: undefined }] }],
      customersInvoiceList: [], // 主页开户主体所有数据

      // 客户的新建数据 ==> (增)
      addCustomer: {
        projectNameType: 0,
        projectName: '',
        exitCustomer: {}, //存在的客户进入编辑信息
        channelList: [
          { text: '巨量引擎', value: 'TT' },
          { text: '腾讯广告', value: 'GDT' },
          { text: '磁力引擎', value: 'KS' },
          { text: '巨量千川', value: 'QC' },
        ],
        allCustomerList: [], //所有的客户信息
        saleList: [], //新增会话框中，获取销售人员
        existChannel: [], //新增会话框中，媒体与销售存在的媒体渠道
        selectExiVisible: false, //新增客户存在是否进入编辑框提示modal
        addModalVisible: false,
        invoiceNameList: [], //所有的开户主体
        addCustomerData: {}, //新建的数据字段
        rules: {
          customerOfCompanyName: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入客户名称',
            },
          ],
        },
      },

      //客户的编辑数据
      editCustomer: {
        activeKey: '1',
        existChannel: [], //编辑会话框中存在的媒体渠道
        modalVisible: false,
        editCustomerData: {},
        customersInvoiceBasicInfoEntityList: [], //编辑新增的发票信息
        customersContractAddresseeEntities: [], //编辑新增的合同信息
        invoiceNameList: [], //编辑新增的开户主体
        editMediaSale: [], //编辑的媒体与销售列表
        companyCode: '', //公司客户code
        //编辑框的格式校验
        editRules: {
          customerOfCompanyName: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        },
      },

      //客户的详情数据 ==> (查)
      customerDetail: {
        companyCode: '', //查看公司的code码
        customerDetailsVisible: false,
        customerName: '',
        activeKey: '1',
        collapseActiveKey: '1',
        channelType: '', //客户的媒体渠道
        customerDetailsMessage: {}, //客户详情信息(基本信息)

        mdaccountIds: '', //开户主体的id
        mdaccountList: [], //开户主体数据
        mdaccountTotal: Number, //开户主体数量
        mdaccountPagination: {
          page: '1',
          limit: 10,
          pageSize: 10,
          showTotal: total => `总计${total}条数据`,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50'],
        },

        customerMediaMessage: [], //客户媒体信息(媒体 ==> 表格)
        customerMediaTotal: Number, //客户媒体信息数量
        mediaPagination: {
          //客户媒体信息分页器
          page: '1',
          limit: 10,
          pageSize: 10,
          showTotal: total => `总计${total}条数据`,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50'],
        },

        customerProjectMessage: [], //客户产品信息(产品 ==> 表格)
        customerProductTotal: Number, //客户产品数量
        productPagination: {
          //客户产品分页器
          page: '1',
          limit: 10,
          pageSize: 10,
          showTotal: total => `总计${total}条数据`,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50'],
        },

        costomerMediaAccount: [], //客户媒体账号信息(媒体账号 ==> 表格)
        customerMediaAccountTotal: Number, //客户媒体账号数量
        showMediaAccountLoading: false,
        mediaAccountPagination: {
          //客户媒体账号分页器
          page: '1',
          limit: 10,
          pageSize: 10,
          showTotal: total => `总计${total}条数据`,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50'],
        },

        financeMessage: [], //客户的财务信息
        financeMessageTotal: Number, //客户财务信息数量
        rules: {
          name: [
            {
              required: true,
              trigger: 'blur',
            },
          ],
        },
        // 查看客户开户主体表单
        subjectColumns: [
          {
            title: '开户主体',
            dataIndex: 'invoiceName',
            key: 'invoiceName',
            slots: {
              customRender: 'invoiceName',
            },
          },
          {
            title: '累计消耗',
            dataIndex: 'cost',
            key: 'cost',
            slots: {
              customRender: 'cost',
            },
          },
          {
            title: '账户数量',
            dataIndex: 'mdaccountCount',
            key: 'mdaccountCount',
            slots: {
              customRender: 'mdaccountCount',
            },
          },
          {
            title: '当前余额',
            dataIndex: 'balance',
            key: 'balance',
            slots: {
              customRender: 'balance',
            },
          },
        ],
        // 查看客户媒体表单
        mediaColumns: [
          {
            title: '媒体',
            dataIndex: 'channelType',
            key: 'channelType',
            slots: {
              customRender: 'channelType',
            },
          },
          {
            title: '销售',
            dataIndex: 'salerName',
            key: 'salerName',
            slots: {
              customRender: 'salerName',
            },
          },

          {
            title: '累计消耗',
            dataIndex: 'cost',
            key: 'cost',
            slots: {
              customRender: 'cost',
            },
          },
          {
            title: '账户数量',
            dataIndex: 'mdaccountCount',
            key: 'mdaccountCount',
            slots: {
              customRender: 'mdaccountCount',
            },
          },
        ],
        //  查看客户产品表单
        productColumns: [
          {
            title: '产品ID',
            dataIndex: 'projectCode',
            key: 'projectCode',
            slots: {
              customRender: 'projectCode',
            },
          },
          {
            title: '产品名称',
            dataIndex: 'projectName',
            key: 'projectName',
            slots: {
              customRender: 'projectName',
            },
          },

          {
            title: '累计消耗',
            dataIndex: 'cost',
            key: 'cost',
            slots: {
              customRender: 'cost',
            },
          },
        ],

        // 查看客户媒体账号
        accountColumns: [
          {
            title: '媒体账号ID',
            dataIndex: 'mdaccountId',
            key: 'mdaccountId',
            width: 180,
            fixed: 'left',
            slots: {
              customRender: 'mdaccountId',
            },
          },
          {
            title: '产品ID',
            dataIndex: 'projectCode',
            key: 'projectCode',
            width: 180,
            fixed: 'left',
            slots: {
              customRender: 'projectCode',
            },
          },

          {
            title: '产品名称',
            dataIndex: 'projectName',
            key: 'projectName',
            slots: {
              customRender: 'projectName',
            },
          },
          {
            title: '媒体',
            dataIndex: 'channelType',
            key: 'channelType',
            slots: {
              customRender: 'channelType',
            },
          },
          {
            title: '开户主体',
            dataIndex: 'invoiceName',
            key: 'invoiceName',
            slots: {
              customRender: 'invoiceName',
            },
          },
          {
            title: '累计消耗',
            dataIndex: 'cost',
            key: 'cost',
            slots: {
              customRender: 'cost',
            },
          },
          {
            title: '当前消耗情况',
            dataIndex: 'name',
            key: 'name',
            slots: {
              customRender: 'name',
            },
          },
        ],
        viewAccountPagination: {
          limit: '10',
          page: '1',
        },
        viewMediaPagination: {
          limit: '10',
          page: '1',
        },
      },

      // 返点信息
      reBatInfo: {
        visible: false,
      },
      // 回款周期
      payPeriod: {
        visible: false,
      },
    };
  },
  mounted() {
    this.getCustomerList();
    this.getCustomersInvoiceList();
    this.saveUserByJobList();
    this.saveInvoiceNameList();
  },
  methods: {
    mapColumn(key) {
      if (key) {
        return this.addCustomer.channelList.filter(item => {
          return item.value == key;
        })[0].text;
      }
    },
    //获取主页查询的开户主体下拉框数据
    async getCustomersInvoiceList() {
      let res = await getCustomersInvoiceListData({});
      this.customersInvoiceList = res.code === 0 && res.page;
    },

    // 获取所有客户数据
    async getCustomerAllList() {
      let res = await getCustomerAllListData();
      this.addCustomer.allCustomerList = res.code === 0 && res.list;
    },

    // 获取客户分页数据
    async getCustomerList() {
      if (this.homeTableLoading === true) {
        return;
      }
      this.homeTableLoading = true;
      if (this.customerFilter.customerOfCompanyName) {
        this.customerFilter.customerOfCompanyName = this.customerFilter.customerOfCompanyName.trim();
      }

      let postParams = {
        ...this.customerFilter,
        ...this.homeTablePagination,
      };
      let res = await getCustomerPageData(postParams);
      this.customerDataSource = res.code === 0 && res.page.list;
      this.homeTablePagination.pageSize = res.page.pageSize;
      this.homeTablePagination.total = res.page.totalCount;
      this.homeTableLoading = false;
    },
    /** 回车筛选客户名称
     * @param {*}
     * @return {*}
     */
    enterCustomerSearch() {
      this.homeTablePagination.page = '1';
      this.getCustomerList();
    },
    /**筛选客户事件
     * @param {*}
     * @return {*}
     */
    onFilterCustomerBtnClick() {
      if (this.customerFilter.channelTypeList && this.customerFilter.channelTypeList.length > 0) {
        this.customerColumns.splice(1, 1, {
          title: '媒体',
          dataIndex: 'channelType',
          key: 'channelType',
          slots: {
            customRender: 'channelType',
          },
        });
      } else {
        this.customerColumns.splice(1, 1, {
          title: '媒体数量',
          dataIndex: 'channelNum',
          key: 'channelNum',
          slots: {
            customRender: 'channelNum',
          },
        });
      }
      this.homeTablePagination.page = '1';
      this.getCustomerList();
    },

    /**重置客户事件
     * @param {*}
     * @return {*}
     */
    onResetCustomerBtnClick() {
      this.customerFilter = {};
      this.customerColumns.splice(1, 1, {
        title: '媒体数量',
        dataIndex: 'channelNum',
        key: 'channelNum',
        slots: {
          customRender: 'channelNum',
        },
      });
      this.homeTablePagination.page = '1';
      this.getCustomerList();
    },

    /** 主页表格分页
     * @param {*}
     * @return {*}
     */
    homeTableChange(pagination) {
      this.homeTablePagination.page = pagination.current.toString();
      this.homeTablePagination.limit = pagination.pageSize.toString();
      this.homeTablePagination.pageSize = pagination.pageSize;
      this.getCustomerList();
    },
    // *********************************新增页面的API (增)********************************************

    /**新建客户事件
     * @param {*} 增
     * @return {*}
     */
    onAddCustomerBtnClick() {
      this.getCustomerAllList();
      this.addCustomer.addCustomerData = {};
      this.addCustomer.addModalVisible = true;
    },
    onCreateCusProjectNameTypeUpdate(){
      if(this.addCustomer.projectNameType ==0){
        this.addCustomer.projectName = this.addCustomer.addCustomerData.customerOfCompanyName;
      }else {
        this.addCustomer.projectName = '';
      }
    },

    //搜索新建会话框客户
    onCreateCusSelectSearch(value) {
      let inputValue = value.trim();
      if (inputValue.length > 100) {
        if (this.messageShowing != true) {
          this.messageShowing = true;
          this.$message.warn('客户名称最大长度为100个字符', 3, function () {
            this.messageShowing = false;
          });
        }

        this.addCustomer.addCustomerData.customerOfCompanyName = inputValue.slice(0, 100);
        return;
      }
      let flag = false;
      for (let item of this.addCustomer.allCustomerList) {
        if (item.customerOfCompanyName.indexOf(inputValue) !== -1) {
          flag = true;
        }
      }
      if (!flag) {
        this.addCustomer.addCustomerData.customerOfCompanyName = inputValue;
      }
    },
    //选择存在的客户
    onCreateCusSelect(value) {
      this.addCustomer.selectExiVisible = true;
      for (let item of this.addCustomer.allCustomerList) {
        if (item.customerOfCompanyName.indexOf(value) !== -1) {
          this.addCustomer.exitCustomer = item;
        }
      }
    },
    confirmEnterEdit() {
      this.addCustomer.selectExiVisible = false;
      this.addCustomer.addModalVisible = false;
      this.editCustomer.modalVisible = true;
      let exsitCustomer = this.addCustomer.exitCustomer;
      this.editCustomer.companyCode = exsitCustomer.companyCode;
      this.updateCustomerByCode();
    },
    //取消进入编辑
    cancelEnterEdit() {
      this.addCustomer.selectExiVisible = false;
      this.addCustomer.addCustomerData.customerOfCompanyName = '';
    },

    // 新建会话框确认
    async confirmAddCustomer() {
      this.$refs.addModalForm.validate().then(res => {
        let customerSalerIdMap = {};
        for (let item of this.arrList) {
          customerSalerIdMap[item.channelType] = [];
          for (let option of item.sale) {
            customerSalerIdMap[item.channelType].push(option.salerId);
          }
        }
        let postParams = {
          customerSalerIdMap,
          ...this.addCustomer.addCustomerData,
        };
        if (this.formState.delivery == true){
          if (this.addCustomer.projectNameType==1)
            postParams.projectName = this.addCustomer.projectName;
          else
            postParams.projectName = this.addCustomer.addCustomerData.customerOfCompanyName;
        };
        saveCustomerData(postParams).then(res => {
          if (res.code === 0) {
            this.$message.success('新建成功');
            this.addCustomer.addModalVisible = false;
            this.getCustomerList();
            this.addCustomer.addCustomerData = {};
            this.addCustomer.existChannel = [];
            this.arrList = [{ channelType: undefined, sale: [{ salerId: undefined }] }];
          }
        });
      });
    },
    //新建会话框取消
    cancelAddCustomer() {
      this.addCustomer.existChannel = [];
      this.arrList = [{ channelType: undefined, sale: [{ salerId: undefined }] }];
      this.addCustomer.addCustomerData = {};
      this.$refs.addModalForm.resetFields();
    },

    // 获取所有开户主体(新增和编辑界面)
    async saveInvoiceNameList() {
      let res = await saveInvoiceNameListData({});
      this.addCustomer.invoiceNameList = res.code === 0 && res.list;
    },

    //选择媒体
    deselectAddChannel(value) {
      this.addCustomer.existChannel = [];
      for (let i = 0; i < this.arrList.length; i++) {
        this.addCustomer.existChannel.push(this.arrList[i].channelType);
      }
    },
    //禁用已经选择的媒体
    filterAddExist(value) {
      for (let item of this.addCustomer.existChannel) {
        if (item == value) {
          return true;
        }
      }
    },
    //获取销售人员列表
    async saveUserByJobList() {
      let res = await saveUserByJobData('SELL');
      this.addCustomer.saleList = res.code === 0 && res.list;
    },
    addChannel(index) {
      this.arrList.push({ channelType: undefined, sale: [{ salerId: undefined }] });
    },

    deleteChannel(arrList, index) {
      arrList.splice(index, 1);
      this.deselectAddChannel();
    },
    addSaler(optionsList) {
      optionsList.push({ value: undefined });
    },
    deleteSaler(optionsList, key) {
      optionsList.splice(key, 1);
    },
    // *********************************编辑页面API (改)******************************************
    /** 编辑客户
     * @param {*} record 客户的详情数据
     * @return {*}
     */
    onEditBtnClick(record) {
      this.editCustomer.activeKey = '1';
      this.editCustomer.companyCode = record.companyCode;
      this.editCustomer.modalVisible = true;
      this.updateCustomerByCode();
    },
    //改变编辑会话框中的开户主体
    customerDeselect(value) {
      this.editCustomer.invoiceNameList.push(value);
      this.$message.warn('请勿删除开户主体！');
    },
    // 根据公司Code获取客户详细信息
    async updateCustomerByCode() {
      let companyCode = this.editCustomer.companyCode;
      let res = await updateCustomerByCompanyCodeData(companyCode);
      this.editCustomer.editCustomerData = res.code === 0 && res.vo;
      this.editCustomer.invoiceNameList = res.code === 0 && res.vo.invoiceNameList;
      this.editCustomer.customersInvoiceBasicInfoEntityList = res.code === 0 && res.vo.customersInvoiceBasicInfoEntityList;
      this.editCustomer.customersContractAddresseeEntities = res.code === 0 && res.vo.customersContractAddresseeEntities;
      let customerSalerInfoMap = res.vo.customerSalerInfoMap;
      if (JSON.stringify(customerSalerInfoMap) == '{}') {
        customerSalerInfoMap[''] = [''];
      }
      this.editCustomer.channelList = Object.keys(customerSalerInfoMap); //['TT','GDT']
      this.editCustomer.editMediaSale = []; //我需要的
      for (let item of this.editCustomer.channelList) {
        this.editCustomer.editMediaSale.push({ channelType: item, sale: [] });
      }
      for (let i = 0; i < this.editCustomer.channelList.length; i++) {
        for (let option of this.editCustomer.editMediaSale) {
          if (option.channelType == this.editCustomer.channelList[i]) {
            for (let item of customerSalerInfoMap[this.editCustomer.channelList[i]]) {
              option.sale.push({ ...item });
            }
          }
        }
      }
    },
    //确认编辑的发票信息
    async confirmEditCustomer() {
      this.$refs.editModal.validate().then(() => {
        let customerSalerIdMap = {};
        for (let item of this.editCustomer.editMediaSale) {
          customerSalerIdMap[item['channelType']] = [];
          for (let option of item.sale) {
            customerSalerIdMap[item['channelType']].push(option.salerId);
          }
        }
        let postParams = {
          ...this.editCustomer.editCustomerData,
          customerSalerIdMap: customerSalerIdMap,
          invoiceNameList: this.editCustomer.invoiceNameList,
        };
        updateCustomerData(postParams).then(res => {
          if (res.code === 0) {
            this.$message.success('修改成功');
            this.editCustomer.modalVisible = false;
            this.getCustomerList();
          }
        });
      });
    },
    //取消编辑的发票信息
    cancelEditCustomer() {
      this.editCustomer.existChannel = [];
      this.$refs.editModal.resetFields();
    },
    // 编辑框选择的媒体
    selectEditChannel(value) {
      this.editCustomer.existChannel = [];
      for (let i = 0; i < this.editCustomer.editMediaSale.length; i++) {
        this.editCustomer.existChannel.push(this.editCustomer.editMediaSale[i].channelType);
      }
    },
    //禁用已经选择的媒体
    filterUpdateExist(value) {
      for (let item of this.editCustomer.existChannel) {
        if (item == value) {
          return true;
        }
      }
    },
    //删除编辑中的媒体
    deleteEditChannel(arrList, index) {
      arrList.splice(index, 1);
      this.selectEditChannel();
    },

    addEditChannel() {
      this.editCustomer.editMediaSale.push({ channelType: undefined, sale: [{ salerId: undefined }] });
    },

    // 编辑新建发票信息
    addTicket() {
      this.editCustomer.customersInvoiceBasicInfoEntityList.push({ companyCode: this.editCustomer.companyCode });
    },
    //删除新建的发票信息
    deleteTicketMessage(index) {
      this.editCustomer.customersInvoiceBasicInfoEntityList.splice(index, 1);
      this.$message.success('删除成功');
    },

    // 编辑新建收件信息
    addReceive() {
      this.editCustomer.customersContractAddresseeEntities.push({ companyCode: this.editCustomer.companyCode });
    },
    //删除新建的收件信息
    deleteReceiveMessage(index) {
      this.editCustomer.customersContractAddresseeEntities.splice(index, 1);
      this.$message.success('删除成功');
    },

    // *********************************查看页面的API (查)********************************************
    onViewBtnClick(record) {
      this.customerDetail.companyCode = record.companyCode;
      this.customerDetail.mdaccountIds = record.mdaccountIds;
      this.customerDetail.channelType = record.channelType;
      this.customerDetail.customerName = record.customerOfCompanyName;
      this.customerDetail.customerDetailsVisible = true;
      this.customerDetail.mdaccountPagination.page = '1';
      this.customerDetail.mediaPagination.page = '1';
      this.customerDetail.productPagination.page = '1';
      this.customerDetail.mediaAccountPagination.page = '1';
      this.viewBasicMessage(); //基本信息
      this.viewCustomerInvoiceInfo(); //开户主体
      this.viewCustomerMediaInfo(); //媒体
      this.viewCustomerProjectInfo(); //产品
      this.viewCustomerMdAccountInfo(); //媒体账号
      this.viewCustomerInvoiceBasicInfo(); //财务信息
    },

    //查看客户基本信息
    async viewBasicMessage() {
      let companyCode = this.customerDetail.companyCode;
      let res = await viewCustomerMessageData(companyCode);
      this.customerDetail.customerDetailsMessage = res.code === 0 && res.vo;
    },

    //查看界面-获取客户开户主体信息
    async viewCustomerInvoiceInfo() {
      let postParams = {
        companyCode: this.customerDetail.companyCode,
        mdaccountIds: this.customerDetail.mdaccountIds,
      };
      let res = await viewCustomerInvoiceInfoData(postParams);
      this.customerDetail.mdaccountTotal = res.code === 0 && res.page.totalCount;
      this.customerDetail.mdaccountList = res.code === 0 && res.page.list;
      this.customerDetail.mdaccountPagination.current = res.code === 0 && res.page.currPage;
      this.customerDetail.mdaccountPagination.total = res.code === 0 && res.page.totalCount;
      this.customerDetail.mdaccountPagination.pageSize = res.code === 0 && res.page.pageSize;
    },

    //查看开户主体的表单切换
    viewSubjectTable(pagination) {
      this.customerDetail.mdaccountPagination.page = pagination.current.toString();
      this.customerDetail.mdaccountPagination.limit = pagination.pageSize;
      this.viewCustomerInvoiceInfo();
    },

    //查看界面-获取客户媒体信息
    async viewCustomerMediaInfo() {
      let channelList = [];
      if (this.customerDetail.channelType) {
        channelList.push(this.customerDetail.channelType);
      } else {
        channelList = ['TT', 'GDT', 'KS','QC'];
      }
      let postParams = {
        companyCode: this.customerDetail.companyCode,
        channelTypeList: channelList,
      };
      let res = await viewCustomerMediaInfoData(postParams);
      this.customerDetail.customerMediaMessage = res.code === 0 && res.page.list;
      this.customerDetail.current = res.page.currPage;
      this.customerDetail.total = res.page.totalCount;
      this.customerDetail.customerMediaTotal = res.page.totalCount;
      this.customerDetail.pageSize = res.page.pageSize;
    },
    //查看界面-获取客户媒体信息(分页)
    viewMediaTable(pagination) {
      this.customerDetail.mediaPagination.page = pagination.current.toString();
      this.customerDetail.mediaPagination.limit = pagination.pageSize;
      this.viewCustomerMediaInfo();
    },

    //查看-获取客户产品信息
    async viewCustomerProjectInfo() {
      let postParams = {
        companyCode: this.customerDetail.companyCode,
        mdaccountIds: this.customerDetail.mdaccountIds,
        ...this.customerDetail.productPagination,
      };
      let res = await viewCustomerProjectInfoData(postParams);
      this.customerDetail.customerProjectMessage = res.code === 0 && res.page.list;
      this.customerDetail.customerProductTotal = res.page.totalCount;
      this.customerDetail.productPagination.total = res.page.totalCount;
      this.customerDetail.productPagination.pageSize = res.page.pageSize;
      this.customerDetail.productPagination.current = res.page.currPage;
    },
    //查看界面-获取产品信息(分页)
    viewProductTable(pagination) {
      this.customerDetail.productPagination.page = pagination.current.toString();
      this.customerDetail.productPagination.limit = pagination.pageSize;
      this.viewCustomerProjectInfo();
    },

    //查看-获取客户媒体账号信息
    async viewCustomerMdAccountInfo() {
      this.customerDetail.showMediaAccountLoading = true;
      let postParams = {
        companyCode: this.customerDetail.companyCode,
        mdaccountIds: this.customerDetail.mdaccountIds,
        ...this.customerDetail.mediaAccountPagination,
      };
      let res = await viewCustomerMdAccountInfoData(postParams);
      this.customerDetail.costomerMediaAccount = res.code === 0 && res.pag.list;
      this.customerDetail.mediaAccountPagination.total = res.pag.totalCount;
      this.customerDetail.mediaAccountPagination.pageSize = res.pag.pageSize;
      this.customerDetail.customerMediaAccountTotal = res.pag.totalCount;
      this.customerDetail.mediaAccountPagination.current = res.pag.currPage;
      this.customerDetail.showMediaAccountLoading = false;
    },
    //媒体账号信息 - 分页
    viewAccountTable(pagination) {
      this.customerDetail.mediaAccountPagination.page = pagination.current.toString();
      this.customerDetail.mediaAccountPagination.limit = pagination.pageSize;
      this.viewCustomerMdAccountInfo();
    },

    //财务信息
    async viewCustomerInvoiceBasicInfo() {
      let postParams = {
        companyCode: this.customerDetail.companyCode,
      };
      let res = await viewCustomerInvoiceBasicInfoData(postParams);
      this.customerDetail.financeMessage = res.code === 0 && res.page.list;
      this.customerDetail.financeMessageTotal = res.code === 0 && res.page.totalCount;
    },
    // 表格操作项
    operationClick(record, type) {
      this.rowData = record;
      switch (type) {
        // 返点
        case 'rebate':
          this.$nextTick(() => {
            this.$refs.rebateInfo.visible = true;
          });
          break;
        // 回款周期
        case 'payPeriod':
          this.$refs.payPeriod.visible = true;
          break;
      }
    },
    // 表格操作项弹框点击保存后的回调
    afterSave(data) {},
  },
};
</script>

<style lang="less">
.cusotmer-drawer {
  .ant-drawer-content-wrapper {
    overflow: auto;
    .ant-drawer-content {
      min-width: 500px;
      .ant-drawer-header {
        .ant-drawer-title {
          font-size: 0.5rem;
          color: #72a4f2 !important;
        }
      }
      .ant-drawer-body {
        .ant-form-item {
          flex-wrap: nowrap;
          .ant-form-item-label {
            line-height: 41px !important;
            text-align: right;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.add-modal {
  width: 620px !important;
  .ant-modal-content {
    .ant-modal-body {
      height: 480px !important;
      overflow: auto;
      .ant-form-item {
        flex-wrap: nowrap;
        .ant-form-item-label {
          line-height: 41px !important;
          padding: 0;
          text-align: right;
          margin-right: 10px;
        }
      }
      .addModalInput {
        display: flex;
        justify-content: space-around;
        .addModalInput-content {
          display: flex;
          width: 45%;
          .ant-form-item {
            margin-bottom: 0;
            .ant-form-item-control {
              width: 100%;
            }
          }
          .ant-col {
            height: 45px;
            display: flex;
            align-items: center;
            .ant-col-3 {
              padding-left: 8px;
              div {
                width: 0.55rem !important;
                height: 0.55rem !important;
                font-size: 0.7rem;
                border: 1px solid #999;
                color: #999;
                border-radius: 50%;
                line-height: 0.35rem;
                text-align: center;
                cursor: pointer;
              }
            }
          }
        }
      }
      .ant-divider {
        .ant-divider-inner-text {
          margin-left: -8px;
        }
      }
    }
  }
}
.addModalInput-content {
  .ant-form-item {
    width: 100%;
    .ant-form-item-control {
      width: 100% !important;
    }
  }
}
.editMdal {
  width: 800px !important;
  .ant-modal-content {
    .ant-modal-body {
      height: 500px !important;
      .editMdal-form {
        .ant-col {
          height: 45px;
          margin-top: 0.4rem;
          .ant-form-item-label {
            label {
              font-size: 13px;
            }
          }
        }
      }
      .collapse-panel {
        .ant-col {
          height: 50px;
          .ant-form-item-label {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.rebat_drawer {
  .ant-drawer-body {
    height: calc(100% - 55px);
  }
}
</style>
<style lang="less" scoped>
.customer_manage {
  display: flex;
  flex-direction: column;
  .table_wrapper {
    :deep(.customer_manage_table) {
      .ant-table-scroll .ant-table-body {
        max-height: calc(100vh - 292px) !important;
      }
      .ant-table-pagination {
        margin-bottom: 0 !important;
      }
    }
  }
}

.information {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 3rem;
  .information-add {
    width: 0.6rem;
    height: 0.6rem;
    color: #999;
    text-align: center;
    line-height: 12px;
    font-size: 0.6rem;
    border: 1px solid #999;
    border-radius: 50%;
  }
  .information-add:hover {
    cursor: pointer;
  }
}
.onExistData {
  width: 100%;
  height: 10rem;
  position: relative;
  background: #fff;
  border: 0;
  .onExistData-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 24%;
    height: 4rem;
    color: #bfbfbf;
  }
}
</style>